<template>
  <div
    class="ai-beta-bench"
    v-loading.fullscreen.lock="loading || searchedResultsLoading"
  >
    <CardToolbar
      v-if="!accessToken"
      title="AI Beta Bench"
      description="Discover your Spotify data"
      :show-toolbar-buttons="false"
      class="pb-9"
    >
      <template v-slot:body>
        <div class="mb-5 text-center">
          <h6 class="subheading fw-light mb-3">
            You are not logged in to Spotify, Please login
          </h6>
          <a class="btn btn-sm btn-success me-3" @click="loginToSpotify"
            >Login to Spotify</a
          >
        </div>
      </template>
    </CardToolbar>
    <div v-else>
      <Catalogues />
      <router-view @viewArtistAnalytics="viewArtistAnalytics" />
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import CardToolbar from "../../common/components/CardToolbar";
import { computed, ref } from "vue";
import Catalogues from "./Catalogues";
import { getArtistUUIDBySpotifyPayload } from "../../api/artists.api";
import { ElNotification } from "element-plus";

export default {
  name: "Index",
  components: {
    Catalogues,
    CardToolbar,
  },
  beforeRouteLeave: function (to, from, next) {
    if (!to.path.includes("ai-beta-bench")) {
      this.$store.commit("AIBetaBench/CLEAR_SEARCH_DATA");
    }
    next();
  },
  setup() {
    const store = useStore();
    const loading = ref(false);
    const accessToken = computed(
      () => store.getters["SpotifyAccessTokenHelperModule/getAccessToken"]
    );

    const searchedResultsLoading = computed(() => {
      return store.getters["AIBetaBench/getSearchedResultLoading"];
    });

    //Methods

    const generateRandomString = function (length) {
      let text = "";
      const possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for (let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      }
      return text;
    };

    const loginToSpotify = () => {
      const clientId = process.env.VUE_APP_SPOTIFY_CLIENT_ID;
      const response_type = "code";
      const scope =
        "user-read-private user-read-email user-follow-modify user-follow-read user-library-modify user-library-read playlist-modify-private playlist-read-private playlist-modify-public";
      const redirectUri = window.origin + "/callback-spotify";
      const state = generateRandomString(16);
      store.commit(
        "SpotifyAccessTokenHelperModule/SET_REDIRECT_PATH_AFTER_LOGIN",
        "/ai-beta-bench"
      );
      window.location.href = `https://accounts.spotify.com/authorize?response_type=${response_type}&client_id=${clientId}&scope=${scope}&redirect_uri=${redirectUri}&state=${state}`;
    };

    // End Methods

    return {
      //Vars
      accessToken,
      loading,
      searchedResultsLoading,
      //Methods
      loginToSpotify,
    };
  },

  methods: {
    async viewArtistAnalytics(payload) {
      this.loading = true;
      try {
        const { data } = await getArtistUUIDBySpotifyPayload(payload);
        this.openArtistProfileDrawer(data.uuid);
      } catch (e) {
        ElNotification({
          title: "Error",
          message: "No match found for this artist",
          type: "error",
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped></style>
