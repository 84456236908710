<template>
  <div class="genres-search-modal">
    <div
      class="modal fade"
      id="genres_search_modal"
      tabindex="-1"
      aria-hidden="true"
      role="dialog"
      aria-labelledby="createForecastModal"
      data-backdrop="static"
      data-keyboard="false"
    >
      <!--begin::Modal dialog-->
      <div class="modal-dialog modal-dialog-centered mw-650px">
        <!--begin::Modal content-->
        <div class="modal-content">
          <!--begin::Modal header-->
          <div class="modal-header"></div>
          <!--end::Modal header-->
          <div class="modal-body py-2 px-lg-17">
            <div class="d-flex flex-column mb-8 fv-row">
              <!--begin::Label-->
              <label class="d-flex align-items-center fs-6 fw-bold mb-2">
                <span>Genres</span>
              </label>
              <!--end::Label-->

              <el-form>
                <el-form-item prop="tags">
                  <el-select-v2
                    :options="allGenres"
                    class="w-100"
                    v-model="selectedGenres"
                    filterable
                    placeholder="Choose tags for your target"
                  >
                    <!--                  <el-option-->
                    <!--                    v-bind:key="genre"-->
                    <!--                    v-for="genre in allGenres"-->
                    <!--                    :label="genre"-->
                    <!--                    :value="genre"-->
                    <!--                  >-->
                    <!--                  </el-option>-->
                  </el-select-v2>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <!--begin::Form-->
          <div class="modal-footer flex-center">
            <!--begin::Button-->
            <button
              class="btn btn-primary me-3"
              data-bs-toggle="modal"
              data-bs-target="#genres_search_modal"
            >
              Apply
            </button>
            <button
              type="reset"
              class="btn btn-white me-3"
              data-bs-toggle="modal"
              data-bs-target="#genres_search_modal"
              @click="clearSelectedGenres"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
    <a
      ref="modalOpenButtonRef"
      type="button"
      class="d-none btn btn-sm btn-primary me-3"
      data-bs-toggle="modal"
      data-bs-target="#genres_search_modal"
    ></a>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";

export default {
  name: "GenresSearchModal",
  props: {
    allGenres: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const store = useStore();
    const modalOpenButtonRef = ref(null);
    const selectedGenres = ref(null);

    const showModal = () => {
      modalOpenButtonRef.value.click();
    };

    const genre = computed(() => {
      return store.getters["AIBetaBench/getFilterDataGenre"];
    });

    watch(
      () => genre.value,
      (value) => {
        if (selectedGenres.value !== value) {
          selectedGenres.value = value;
        }
      }
    );

    watch(
      () => selectedGenres.value,
      (value) => {
        store.commit("AIBetaBench/SET_FILTER_DATA_GENRE", value);
      }
    );

    const clearSelectedGenres = () => {
      selectedGenres.value = null;
      store.commit("AIBetaBench/SET_FILTER_DATA_GENRE", null);
    };

    return {
      modalOpenButtonRef,
      selectedGenres,
      genre,
      //Methods
      showModal,
      clearSelectedGenres,
    };
  },
};
</script>

<style lang="scss" scoped>
.genres-search-modal {
  //.watch-lists-tags {
  //  .el-select__input {
  //  }
  //  .el-input__inner {
  //    //min-height: 42px !important;
  //    background-color: #f5f8fa;
  //    border: none !important;
  //    border-radius: 0.475rem !important;
  //  }
  //  .el-tag {
  //    background-color: white !important;
  //  }
  //}
}
</style>
