<template>
  <div class="catalogues">
    <div class="card mb-5 mb-xl-5" v-loading="loading">
      <div class="card-header border-0 pt-5">
        <div>
          <h2 class="heading">Find Catalogues</h2>
        </div>
      </div>
      <div class="card-body pt-3 pb-0">
        <el-row>
          <el-col
            :span="12"
            class="d-flex justify-content-between align-items-center"
          >
            <CatalogueButtons
              :disabled="$route.path !== '/ai-beta-bench/search-results'"
              @buttonClicked="$refs.genresSearchModalRef.showModal()"
              :selected="selectedGenre"
              >Genres</CatalogueButtons
            >
            <GenresSearchModal
              ref="genresSearchModalRef"
              :all-genres="genres"
              @clearSelectedGenres="filtersData.genreId = null"
            />
            <CatalogueButtons
              :disabled="true"
              @buttonClicked="$refs.listenersRangeModalRef.showModal()"
              :selected="filtersData.listenersRange.length > 0"
              >Listeners</CatalogueButtons
            >
            <CatalogueRangeModal
              ref="listenersRangeModalRef"
              type="listeners"
              @change="filtersData.listenersRange = $event"
              @clearCatalogueRange="filtersData.listenersRange = []"
            />
            <CatalogueButtons
              :disabled="true"
              @buttonClicked="$refs.followersRangeModalRef.showModal()"
              :selected="filtersData.followersRange.length > 0"
              >Followers</CatalogueButtons
            >
            <CatalogueRangeModal
              ref="followersRangeModalRef"
              type="followers"
              @change="filtersData.followersRange = $event"
              @clearCatalogueRange="filtersData.followersRange = []"
            />
            <CatalogueButtons
              :disabled="true"
              :selected="filtersData.dateFrom"
              @buttonClicked="$refs.datePickerFromModalRef.showModal()"
              >From</CatalogueButtons
            >
            <CatalogueDatePickerModal
              ref="datePickerFromModalRef"
              type="from"
              @change="dateSelectedFrom"
            />
            <CatalogueButtons
              :disabled="true"
              :selected="filtersData.dateTo"
              @buttonClicked="$refs.datePickerToModalRef.showModal()"
              >Till</CatalogueButtons
            >
            <CatalogueDatePickerModal
              ref="datePickerToModalRef"
              type="to"
              @change="dateSelectedTo"
            />
            <CatalogueButtons :disabled="true">Other</CatalogueButtons>
          </el-col>
          <el-col
            :span="12"
            class="d-flex justify-content-end align-items-center"
          >
            <div>
              <CardHeaderButtonAI
                :disabled="$route.path !== '/ai-beta-bench/search-results'"
                @buttonClicked="searchFilteredData"
                :solid="true"
                >Search</CardHeaderButtonAI
              >
            </div>
          </el-col>
        </el-row>
        <div class="d-flex overflow-auto h-55px">
          <ul
            class="
              nav nav-stretch nav-line-tabs nav-line-tabs-2x
              border-transparent
              fs-5
              fw-bolder
              flex-nowrap
            "
          >
            <li class="nav-item">
              <router-link
                to="/ai-beta-bench/search-results"
                class="nav-link text-active-primary me-6"
                active-class="active"
              >
                Search
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                to="/ai-beta-bench/listeners"
                class="nav-link text-active-primary me-6"
                active-class="active"
              >
                Listeners
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="`/ai-beta-bench/playlist`"
                class="nav-link text-active-primary me-6"
                active-class="active"
              >
                Playlist
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="`/ai-beta-bench/followers`"
                class="nav-link text-active-primary me-6"
                active-class="active"
              >
                Followers
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="`/ai-beta-bench/fanbase`"
                class="nav-link text-active-primary me-6"
                active-class="active"
              >
                Fanbase
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CatalogueButtons from "../components/CatalogueButtons";
import CardHeaderButtonAI from "../components/CardHeaderButtonAI";
import GenresSearchModal from "../components/GenresSearchModal";
import { computed, onMounted, ref } from "vue";
import CatalogueRangeModal from "../components/CatalogueRangeModal";
import CatalogueDatePickerModal from "../components/CatalogueDatePickerModal";
import { useStore } from "vuex";
import { ElNotification } from "element-plus";
import useSpotifyApi from "../../common/composables/useSpotifyApi";
import { useRouter } from "vue-router";
import { saveDataIntoToBeScrappedDocument } from "../../api/mongoDB";
export default {
  name: "Catalogues",
  components: {
    CatalogueDatePickerModal,
    CatalogueRangeModal,
    GenresSearchModal,
    CardHeaderButtonAI,
    CatalogueButtons,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const genresSearchModalRef = ref(null);
    const listenersRangeModalRef = ref(null);
    const followersRangeModalRef = ref(null);
    const datePickerFromModalRef = ref(null);
    const datePickerToModalRef = ref(null);
    const loading = ref(false);

    const filtersData = ref({
      genreId: null,
      listenersRange: [],
      followersRange: [],
      dateFrom: null,
      dateTo: null,
    });

    const searchResults = ref({
      artists: [],
    });

    const genres = computed(() => {
      return store.getters["DiscoverModule/getAllGenres"];
    });

    const resetData = () => {
      store.commit("AIBetaBench/SET_FILTER_DATA_GENRE", null);
      store.commit("AIBetaBench/CLEAR_SEARCH_DATA");
    };

    // Filter Data Computed Properties
    const selectedGenre = computed(() => {
      return store.getters["AIBetaBench/getFilterDataGenre"];
    });
    // const selectedFollowersRange = computed(() =)
    // End Filter Data Computed Properties

    const searchFilteredData = async () => {
      loading.value = true;
      store.commit("AIBetaBench/SET_SEARCHED_RESULTS_LOADING", true);
      if (selectedGenre.value) {
        // await store.dispatch("AIBetaBench/fetchArtistsByGenre");

        let { total } = await fetchArtistsByFilters(0, {
          genre: selectedGenre.value,
        });
        if (total) {
          router.push("/ai-beta-bench/search-results");
          const promises = [];
          let loopCounter;
          if (total >= 1000) {
            total = 1000;
            loopCounter = 20;
          } else {
            loopCounter = Math.ceil(total / 50);
          }
          for (let i = 0; i < loopCounter; i++) {
            promises.push(
              new Promise((resolve) => {
                fetchArtistsByFilters(i, {
                  genre: selectedGenre.value,
                })
                  .then(({ artists }) => {
                    searchResults.value.artists.push(...artists);
                    resolve();
                  })
                  .catch(() => {
                    resolve();
                  });
              })
            );
          }
          await Promise.all(promises);
          const artistListOfSpotifyIDAndNameOfSameGenres =
            searchResults.value.artists.map((artist) => {
              return {
                spotifyId: artist.id,
                name: artist.name,
              };
            });
          saveDataIntoToBeScrappedDocument(
            artistListOfSpotifyIDAndNameOfSameGenres
          );
          store.commit(
            "AIBetaBench/SET_SEARCHED_ARTISTS",
            searchResults.value.artists
          );
          store.commit("AIBetaBench/SET_SEARCHED_ARTISTS_PAGINATION", {
            offset: 1,
            limit: 10,
            total,
          });
          store.commit("AIBetaBench/CLEAR_SEARCH_LISTENERS");
          searchResults.value.artists = [];
        } else {
          ElNotification({
            title: "Error",
            message: "Error getting data from Spotify",
            type: "error",
          });
        }
      } else {
        ElNotification({
          title: "Error",
          message: "Please select genre",
          type: "error",
        });
      }
      store.commit("AIBetaBench/SET_SEARCHED_RESULTS_LOADING", false);
      loading.value = false;
    };

    const fetchArtistsByFilters = async (offset, filters) => {
      const parsedArtists = [];
      const next = null;
      if (offset < 20) {
        const spotifyApi = await useSpotifyApi(store);
        try {
          const {
            body: { artists },
          } = await spotifyApi.search(`genre:"${filters.genre}"`, ["artist"], {
            limit: 50,
            offset: 50 * offset,
          });
          if (artists.items && artists.items.length > 0) {
            if (
              filters.followersRange &&
              filters.followersRange.length > 0 &&
              filters.followersRange[0] &&
              filters.followersRange[1]
            ) {
              artists.items = artists.items.filter((artist) => {
                return (
                  artist.followers.total >= filters.followersRange[0] &&
                  artist.followers.total <= filters.followersRange[1]
                );
              });
            }
            for (const item of artists.items) {
              parsedArtists.push({
                id: item.id,
                name: item.name,
                image: item.images[0]
                  ? item.images[0].url
                  : "/media/avatars/blank.png",
                followers: item.followers.total,
                popularity: item.popularity,
              });
            }
            return {
              next: artists.next,
              artists: parsedArtists,
              total: artists.total,
            };
          }
        } catch (e) {
          console.log(e);
        }
      }
      return { next, parsedArtists };
    };

    //Callbacks from child components
    const dateSelectedFrom = (date) => {
      filtersData.value.dateFrom = date;
      // datePickerToOptions.value = {
      //   disabledDate(time) {
      //     return time.getTime() > date;
      //   },
      // };
    };

    const dateSelectedTo = (date) => {
      filtersData.value.dateTo = date;
      // datePickerFromOptions.value = {
      //   disabledDate(time) {
      //     return time.getTime() > date;
      //   },
      // };
    };

    onMounted(async () => {
      loading.value = true;
      // resetData();
      await store.dispatch("DiscoverModule/fetchGenres");
      // const { data } = await getAllGenres();
      // genres.value = data.map((object) => {
      //   return { value: object.name, label: object.name };
      // });
      loading.value = false;
    });

    return {
      genresSearchModalRef,
      listenersRangeModalRef,
      followersRangeModalRef,
      datePickerFromModalRef,
      datePickerToModalRef,
      selectedGenre,
      genres,
      filtersData,
      loading,
      //Methods
      dateSelectedFrom,
      dateSelectedTo,
      searchFilteredData,
      resetData,
    };
  },
};
</script>

<style lang="scss" scoped>
.catalogues {
  .search-button {
    padding: 0.5rem 1.5rem !important;
    border-radius: 10px;
  }
}
</style>
